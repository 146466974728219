<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu menu="career"></company-submenu>

      <div class="submenu-content">
          <!-- image -->
        <img v-if="career_banner" 
          :src="career_banner.src"
          style="width:100%">
        <div class="row">
            <!-- paragraf -->
            <div class="col-9" v-html="career_para"></div>
            <!-- social media -->
            <div class="col-3">
                <br>
                <p style="font-weight:bold;">Learn More</p>
                <hr style="border:2px solid #ccc;margin:-4px 0 10px 0;color: #ffffff;">
                <p> <router-link :to="{ name: 'JobOpenings'}">Job Openings</router-link></p>
                <p><a href="https://www.eeoc.gov/eeo-policy-statement" target="_blank">Equal Opportunity Employer</a></p>
                <p><a href="https://www.e-verify.gov/" target="_blank">E-Verify</a></p>
                <p><a href="https://www.malabs.com/media/documents/employee_privacy_notice.pdf" target="_blank">California Employee Privacy Notice</a></p>
                <p style="font-weight:bold; padding-top:20px">Stay Connected</p>
                <hr style="border:2px solid #ccc;margin:-4px 0 10px 0;color: #ffffff;">
                <a href="https://www.linkedin.com/company/malabs" target="_blank"><img src="@/assets/images/linkedin_icon.jpg" :height="50"></a> &nbsp
                <a href="https://www.glassdoor.com/Overview/Working-at-MA-Laboratories-EI_IE3274.11,26.htm" target="_blank"><img src="@/assets/images/glassdoor_icon.jpg" :height="50"></a>
                <br><br>
                <img src="@/assets/images/Forbes_2020_Award.png" :height="150" :width="215">
            </div>
        </div>
      </div> 

  </div>
</template>

<script>
import CompanySubmenu from '../../components/nav/CompanySubmenu.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { getAPI } from '../../utils/axios-api'
import { ref } from 'vue'

export default {
  name: 'Careers',
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const career_banner = ref("")
    const career_para = ref("")
    const error = ref(null)

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Careers", link: "Careers"})

    let career_url = '/content/pages/42'
    getAPI.get(career_url).then((response) => {  
            if(response.status === 200){
                career_banner.value = response.data.body[1].value.large
                
                career_para.value = response.data.body[2].value  
                console.log(career_para.value)                        
            }
        })
        .catch(err => {  
            error.value = "No page Found"        
        })
        return { career_banner,career_para,error, breadcrumb_links }
  }
}
</script>

<style scoped>
a{
  color: #555;
}
</style>